import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Text,
  Button,
} from "@chakra-ui/react";
import SEO from "../components/Seo";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import MobileNav from "../components/MobileNav";
import axios from "axios";
import logo from "../images/vgd.png";

import message from "../images/message.svg";

import Swal from "sweetalert2";
// import emailjs, { init } from "emailjs-com";
import "../styles/contact.css";

// init("user_bcT1POGZr6wb1ww9bBJo6");

export default function Tems_and_condtions({ location }) {
  const isBrowser = typeof window !== "undefined";

  return (
    <Box className="main" overflowX="hidden">
      {/* {width > 1280 ? (
        <Nav loc={location} page="/privacy_policy" />
      ) : (
        <MobileNav page="/privacy_policy" />
      )} */}
      <Box>
        <Flex
          w="100%"
          flexDirection="column"
          alignItems="start"
          flexWrap="wrap"
          px="5%"
          mt="60px"
          textAlign={"start"}
        >
          <Flex
            className="contactgetstarted"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
            // gap={"100"}
            w="100%"
            py="10px"
            borderBottom="0.5px solid rgba(0, 0, 0, 0.15);"
          >
            <img
              src={logo}
              alt="visual_group_logo"
              style={{
                objectFit: "cover",
                height: 50,
                width: 218,
                cursor: "pointer",
                marginLeft: -25,
              }}
              onClick={() => {
                window.location.assign("/");
              }}
            />
            <Text
              fontWeight="bold"
              as="h3"
              fontSize={["23px", "23px", "32px", "32px", "32px"]}
            >
              Terms and Conditions
            </Text>

            <Box></Box>
          </Flex>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            1. Introduction
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            Welcome to Ascend Group Media (“we,” “us,” or “our”). By engaging
            our services, you (“client,” “you,” or “your”) agree to abide by
            these Terms and Conditions (“T&Cs”). These T&Cs constitute a legally
            binding agreement between you and Ascend Media.
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            2. Services
          </Text>

          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            2.1 Paid Advertising We will manage your paid advertising campaigns
            on platforms such as Google Ads, Facebook Ads, and other relevant
            channels. <br /> 2.2 Search Engine Optimization (SEO) We will
            optimize your website to improve its search engine ranking,
            including keyword research, on-page SEO, and off-page SEO.
            <br /> 2.3 Web Development We will develop and maintain your
            website, subject to ongoing maintaining fees being paid, ensuring it
            is functional, responsive, and aligned with your business goals.
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            2. Information We Collect
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            We may collect and process the following data:
            <br /> • Personal identification information (Name, email address,
            phone number, etc.) <br /> • Technical data (IP address, browser
            type, version, time zone setting, browser plug-in types, and
            versions, operating system, and platform) <br /> • Usage data
            (Information about how you use our website, products, and services)
            <br />• Marketing and communications data (Your preferences in
            receiving marketing from us and your communication preferences)
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            3. Client Responsibilities
          </Text>

          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            3.1 Information and Access You agree to provide all necessary
            information, access to relevant accounts, and timely responses to
            ensure the effective delivery of our services. <br /> 3.2 Compliance
            You are responsible for ensuring that your content and business
            practices comply with all applicable laws and regulations. <br />
            3.3 Payments <br />
            You agree to make timely payments as per the agreed schedule and
            terms. Kindly note that our Paid Advertising and SEO services
            require a written, one month’s notice period from your last invoice.
          </Text>
          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            4. Payment Terms
          </Text>

          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            4.1 Fees Our fees are outlined in the proposal or agreement provided
            to you. All fees are non-refundable unless otherwise stated.
            <br /> 4.2 Invoicing Invoices will be issued as per the agreed
            schedule, and payments are due within 30 days from the invoice date.
            <br />
            4.3 Late Payments <br />
            Late payments may incur a 10% late fee per month on the outstanding
            amount.
          </Text>
          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            5. Confidentiality
          </Text>

          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            5.1 Confidential Information Both parties agree to keep all
            confidential information private and not disclose it to any third
            parties without prior written consent.
            <br /> 5.2 Exceptions Confidential information does not include
            information that is publicly available, already known to the
            receiving party, or required to be disclosed by law.
          </Text>
          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            6. Intellectual Property
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            6.1 Ownership All materials, content, and deliverables created by
            Ascend Media during the provision of services remain the property of
            Ascend Media until full payment is received.
            <br /> 6.2 Usage Rights Upon full payment, you are granted a
            non-exclusive, non-transferable license to use the deliverables for
            your business purposes.
          </Text>
          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            7. Termination
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            7.1 Termination by Client You may terminate this agreement at any
            time with 30 days’ written notice. Fees for services rendered up to
            the termination date are non-refundable. <br /> 7.2 Termination by
            Agency We reserve the right to terminate this agreement at any time
            with 30 days’ written notice if you breach any terms of this
            agreement.
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            8. Limitation of Liability
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            8.1 No Guarantee We do not guarantee specific results from our
            services, including but not limited to search engine rankings,
            website results or ad performance. <br /> 8.2 Indemnity You agree to
            indemnify and hold harmless Ascend Group Media from any claims,
            damages, or expenses arising from your use of our services. <br />
            8.3 Liability Cap Our liability for any claims arising under this
            agreement is limited to the amount you have paid us for the
            services.
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            9. Governing Law
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            These T&Cs are governed by and construed in accordance with the laws
            of Dubai, UAE, without regard to its conflict of law principles.
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            10. Dispute Resolution
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            Any disputes arising under this agreement shall first be attempted
            to be resolved through mediation.
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            11. Amendments
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            We reserve the right to amend these T&Cs at any time. Any amendments
            will be effective upon posting on our website or notifying you
            directly.
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            12. Entire Agreement
          </Text>
          <Text mt={"5"} fontSize={["15px", "15px", "18px", "18px", "18px"]}>
            These T&Cs constitute the entire agreement between you and Ascend
            Group Media, superseding all prior agreements and understandings.
            directly.
          </Text>

          <Text
            as="h3"
            mt={"10"}
            fontWeight="bold"
            fontSize={["18px", "18px", "20px", "20px", "20px"]}
          >
            13. Contact Information
          </Text>
          <Text
            mt={"5"}
            mb={"20"}
            fontSize={["15px", "15px", "18px", "18px", "18px"]}
          >
            If you have any questions about these T&Cs, please contact us at:
            <br />
            Ascend Group <br /> Media Dubai,
            <br /> UAE Info@ascendgroupmedia.com
            <br /> +971 55 367 2818
          </Text>
        </Flex>

        {/* <Footer /> */}
      </Box>
    </Box>
  );
}
